import styled from 'styled-components'
import { MenuOutlined } from '@ant-design/icons'
import { useState } from 'react'
import DrawerMenu from './DrawerMenu'
import Button from '../../../../widgets/Zeus/Button'

const LINKS = [
  {
    title: 'Home',
    link: '/',
    items: [],
  },
  {
    title: 'Multi chain',
    link: '/multi-chain',
    items: [
      {
        title: 'TON Network',
        link: '/multi-chain#ton-section',
      },
      {
        title: 'BSC Network',
        link: '/multi-chain#bsc-section',
      },
      {
        title: 'SOL Network',
        link: '/multi-chain#sol-section',
      },
      {
        title: 'TRON Network',
        link: '/multi-chain#sol-section',
      },
    ],
  },
  {
    title: 'Docs',
    link: '/docs',
    items: [],
  },
]

const LinkItem = styled.li`
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
`

const Header = () => {
  const [ open, setOpen ] = useState(false)

  return (
    <Wrapper className="w-full">
      <div className="content container flex items-center justify-between w-full">
        <a href="/">
          <img src="/images/landing-2/logo.png" alt="logo" />
        </a>
        <nav>
          <ul>
            {LINKS.map((link) => (
              <LinkItem key={link.title} className={location.pathname === link.link ? 'active' : ''}>
                <a href={link.link}>{link.title}</a>
                {
                  link.items.length > 0 && (
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.1476 7.94229L10.8976 14.1923C10.8396 14.2504 10.7706 14.2965 10.6948 14.328C10.6189 14.3594 10.5376 14.3756 10.4554 14.3756C10.3733 14.3756 10.292 14.3594 10.2161 14.328C10.1402 14.2965 10.0713 14.2504 10.0132 14.1923L3.76324 7.94229C3.64596 7.82502 3.58008 7.66596 3.58008 7.5001C3.58008 7.33425 3.64596 7.17519 3.76324 7.05792C3.88051 6.94064 4.03957 6.87476 4.20543 6.87476C4.37128 6.87476 4.53034 6.94064 4.64761 7.05792L10.4554 12.8665L16.2632 7.05792C16.3213 6.99985 16.3902 6.95378 16.4661 6.92236C16.542 6.89093 16.6233 6.87476 16.7054 6.87476C16.7875 6.87476 16.8689 6.89093 16.9447 6.92236C17.0206 6.95378 17.0895 6.99985 17.1476 7.05792C17.2057 7.11598 17.2517 7.18492 17.2832 7.26079C17.3146 7.33666 17.3308 7.41798 17.3308 7.5001C17.3308 7.58223 17.3146 7.66354 17.2832 7.73941C17.2517 7.81528 17.2057 7.88422 17.1476 7.94229Z"
                        fill="currentColor" />
                    </svg>
                  )
                }
                {
                  link.items.length > 0 && (
                    <ul>
                      {
                        link.items.map((item) => (
                          <li key={item.title}>
                            <a href={item.link}>
                              {item.title}
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                  )
                }
              </LinkItem>
            ))}
          </ul>
        </nav>
        <div className="btn">
          <Button variant={'outlined'} onClick={() => window.open('https://t.me/Zeus88Bot_bot', '_blank')}>
            Open BOT
          </Button>
        </div>

        <MenuIcon onClick={() => setOpen(true)} />
      </div>

      <DrawerMenu visible={open} onClose={() => setOpen(false)} links={LINKS} />
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px 16px 0;
    z-index: 1;

    .container {
        height: 80px;
        border-radius: 16px;
        background: #040709;
        box-shadow: 0px 5px 15px 0px rgba(43, 43, 43, 0.10);
        display: flex;
        padding: 16px 32px;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        nav {
            display: none;
            flex: 1;

            ${({ theme }) => theme.mediaQueries.md} {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            ul {
                display: flex;
                gap: 2rem;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    color: #ADADAD;

                    /* Text lg/Semibold */
                    font-family: var(--font-family-Typeface, Unbounded);
                    font-size: var(--font-size-Text-lg, 18px);
                    font-style: normal;
                    font-weight: 600;
                    line-height: var(--font-line-height-Text-lg, 28px); /* 155.556% */

                    &.active {
                        color: #fff;
                        border-bottom: 1px solid #fff;
                    }

                    a {
                        text-decoration: none;
                        transition: all 0.3s;
                        padding: 8px 0;
                        
                        &:hover {
                            color: #FFC226;
                        }
                    }
                    
                    &:hover {
                        ul {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    
                    ul {
                        transition: .3s all;
                        position: absolute;
                        opacity: 0;
                        visibility: hidden;
                        background: #242424;
                        border-radius: 16px;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        flex-direction: column;
                        gap: 0;
                        width: max-content;
                        
                        li {
                            padding: 1rem 1.5rem;
                        }
                    }
                }
            }
        }

        .btn {
            display: none;

            ${({ theme }) => theme.mediaQueries.md} {
                display: block;
            }
        }
    }
`

const MenuIcon = styled(MenuOutlined)`
    font-size: 24px;
    cursor: pointer;

    ${({ theme }) => theme.mediaQueries.md} {
        display: none;
    }
`
