import styled from 'styled-components'
import Header from '../LandingPage/components/Header'
import HeroBanner from './components/HeroBanner'
import Footer from '../LandingPage/components/Footer'
import TonNetwork from './components/TonNetwork'
import MMPackage from './components/MMPackage'
import BSCNetwork from './components/BSCNetwork'
import SolanaXTron from './components/SolanaXTron'

const MultiChain = () => {
  return (
    <Wrapper className=''>
      <Header />
      <HeroBanner />
      <TonNetwork />
      <MMPackage />
      <BSCNetwork />
      <SolanaXTron />

      <Footer />
    </Wrapper>
  )
}

export default MultiChain

const Wrapper = styled.div`
  color: #fff;
  background-color: #000000;
`
