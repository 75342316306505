import styled from 'styled-components'
import { Flex } from '../../../../widgets'
import { TonVideoWrapper } from '../../../LandingPage/components/TonNetwork'
import Button from '../../../../widgets/Zeus/Button'

const HeroBanner = () => {
  const handleScroll = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Wrapper className="">
      <video src="/images/landing-2/bg-video.mov" autoPlay={true} controls={false} muted loop={true} className={'bg-video'}></video>
      <div className="container">
        <Flex flex={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
          <TonVideoWrapper>
            <video src="/images/landing-2/TON.webm" muted autoPlay loop></video>
          </TonVideoWrapper>
          <Button variant={'transparent'} onClick={() => handleScroll('ton-section')}>
            Detail
          </Button>
        </Flex>
        <Flex justifyContent={'center'} flexDirection={'column'} className={'zeus'}>
          <img src="/images/landing-2/zeus-4.png" alt="zeus" />
          <div className="title">
            Multi-chain Support - One Bot for All Your Trading Needs
          </div>
        </Flex>
        <Flex flex={1} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
          <TonVideoWrapper>
            <video src="/images/landing-2/BSC.webm" muted autoPlay loop></video>
          </TonVideoWrapper>
          <Button variant={'transparent'} onClick={() => handleScroll('bsc-section')}>
            Detail
          </Button>
        </Flex>
      </div>
    </Wrapper>
  )
}

export default HeroBanner

const Wrapper = styled.div`
    position: relative;
    padding: 96px 0 2rem;
    margin-bottom: 5rem;

    ${({ theme }) => theme.mediaQueries.lg} {
        padding: 96px 32px 96px;
    }

    video.bg-video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }

    .zeus {
        order: -1;

        ${({ theme }) => theme.mediaQueries.lg} {
            order: initial;
        }

        img {
            mix-blend-mode: screen;
        }

        .title {
            margin-top: -6rem;
            color: var(--color-text-text-primary, #F1F1F1);
            text-align: center;

            /* Display md/Semibold */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-md, 36px);
            font-style: normal;
            font-weight: 600;
            line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
            letter-spacing: -0.72px;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        ${({ theme }) => theme.mediaQueries.lg} {
            flex-direction: row;
            justify-content: space-between;
        }
    }
`
