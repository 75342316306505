import styled from 'styled-components'
import { flexbox } from 'styled-system'
import Box from './Box'
import { FlexProps } from './types'

interface FlexWithGap extends FlexProps {
  gap?: string
}

const Flex = styled(Box)<FlexWithGap>`
  display: flex;
  gap: ${({ gap }) => gap || '0'};
  ${flexbox}
`

export default Flex
