import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../../../widgets'

const Wrapper = styled.div`
    padding: 100px 0;
    position: relative;
    overflow: hidden;
    
    img[alt="thunder-1"] {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    
    img[alt="thunder-2"] {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    
    .title {
        color: #FFF;
        text-align: center;

        /* Display md/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-md, 36px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
        letter-spacing: -0.72px;
    }

    .subtitle {
        color: #FFF;
        text-align: center;
        padding: 0 1rem;

        ${({ theme }) => theme.mediaQueries.md} {
            padding: 0 10rem;
        }

        /* Display xs/Regular */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-xs, 24px);
        font-style: normal;
        font-weight: 400;
        line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */
    }
`

const MMPackage = () => {
  return (
    <Wrapper>
      <div className="container">
        <img src="/images/landing-2/mm-thunder-1.png" alt="thunder-1" />
        <img src="/images/landing-2/mm-thunder-2.png" alt="thunder-2" />
        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'1.5rem'} position={'relative'}>
          <img src="/images/landing-2/zeus-2.png" alt="zeus" />
          <h2 className="title">Zeus88 MM Packages</h2>
          <p className="subtitle">
            Unlock the full potential of your project with our Zeus88 Marketing and Market-Making (MM) Packages. Secure
            top spots in trending lists and create significant market impact. Our Trending Booking Package offers you 48
            hours of Top 1 Trending for only 1 BNB. Boost your visibility and drive engagement like never before.
          </p>
        </Flex>
      </div>
    </Wrapper>
  )
}

export default MMPackage
