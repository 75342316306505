import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle` 
  * {
    font-family: 'Unbounded';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

   
  } 
  *, 
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .font-inter {
    font-family: 'Inter', sans-serif;
  }

  /* Scrollbar */
  ::-webkit-scrollbar { 
    width: 5px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #FFC737; 
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #FFF1C1; 
    border-radius: 10px;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    color: #060E17; 
    line-height: 1.4;
    font-weight: 400;
    font-size: 14px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 16px;
    }
    
    @media (max-width: 576px) {
      width: 100% !important; 
    } 

    & > iframe {
      display: none; 
    }

    img {
      height: auto;
      max-width: 100%;

      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
  }

  .Toastify__toast-body {
    font-family: 'Poppins';
  }

  .box-error {
    color: red;
    font-family: 'Poppins';
    font-size: 12px;
    padding: 5px;
    text-align:center;
  }

  // ant modal

  .ant-modal-content {
    background: #fff;
    border-radius: 26px;
  }

  .modal-invest-detail {
    .ant-modal-content {
      border-radius: 16px;
    }
  }
 
 

  // ant select dropdown
  .ant-select-dropdown {
    padding: 0;
    margin-top: 6px;
    background: #fff; 
    border-radius: 4px; 
    overflow: hidden;
  }
  .ant-select-item {
    color: #060e17;
    padding: 8px 12px;

  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: #060e17; 
    background: #E8F7FF;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff; 
    background: #3485ff; 
  }

  // tailwindcss
  .flex {
    display: flex;
  }
  .block {
    display: block;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-col {
    flex-direction: column;
  }
  .flex-row-reverse {
    flex-direction: row-reverse
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .items-center {
    align-items: center;
  }
  .font-bold {
    font-weight: 700;
  }
  .rounded {
    border-radius: 10px
  }

  .text-white {
    color: white;
  }
  .text-center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .fixed {
    position: fixed;
  }
  .top-left-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
  .top-left-0 {
    top: 0;
    left: 0;
  }
  .vertical-center {
    top: 50%;
    transform: translateY(-50%)
  }
  .horizontal-center {
    left: 50%;
    transform: translateX(-50%)
  }
  .w-full {
    width: 100%;
  }
  .h-full {
    height: 100%;
  }
  .w-screen {
    width: 100vw;
  }
  .h-screen {
    height: 100vh;
  }
  .overflow-hidden {
    overflow: hidden
  }

  .desktop-only {
    display: none;
    ${({ theme }) => theme.mediaQueries.md} {
      display: unset;
    }
  }


  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem; /* 16px */
    padding-left: 1rem;  /* 16px */
  }

  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .container {
      max-width: 1536px;
    }
  }

  .node-hidden {
    display: none;
  }

  .ant-modal {
    .ant-modal-content {
      background: #070713;
    }


    .ant-modal-body {
      width: 100%;
      height: 100%;
      border: 2px solid #de13f7;
      border-radius: 16px;
      background-image: url('/images/home/HomeCardBG.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding: 20px;
    }
  }

  .ant-select-dropdown {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: rgba(193, 90, 0, 0.69);
      background: rgba(255, 231, 169, 0.54);
    }
  }
`

export default GlobalStyle
