import React from 'react'
import { Flex } from '../../../../widgets'
import styled from 'styled-components'

export const TonVideoWrapper = styled.div`
    width: 200px;
    height: 200px;
    position: relative;

    ${({ theme }) => theme.mediaQueries.md} {
        width: 300px;
        height: 300px;
    }

    video {
        width: 300px;
        position: absolute;
        top: -2%;
        left: 50%;
        transform: translateX(-50%);

        ${({ theme }) => theme.mediaQueries.md} {
            top: 0;
            width: 400px;
        }
    }
`

const TonTitle = styled.h2`
    color: var(--color-text-text-primary, #F1F1F1);
    text-align: center;

    /* Display md/Semibold */
    font-family: var(--font-family-Typeface, Unbounded);
    font-size: var(--font-size-Display-md, 36px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
    letter-spacing: -0.72px;

    ${({ theme }) => theme.mediaQueries.md} {
        color: var(--color-text-text-primary, #F1F1F1);
        text-align: center;

        /* Display 2xl/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-2xl, 72px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-2xl, 90px); /* 125% */
        letter-spacing: -1.44px;
    }
`

const Features = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 16px;
`

const Feature = styled(Flex)`
    gap: 16px;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.md} {
        gap: 64px;
        flex-direction: row;
    }

    img {
        width: 200px;

        ${({ theme }) => theme.mediaQueries.md} {
            width: 450px;
            margin-left: -10%;
        }
    }

    &:nth-child(2) {
        ${({ theme }) => theme.mediaQueries.md} {
            flex-direction: row-reverse;

            img {
                margin-right: -10%;
            }
            
            .subtitle {
                max-width: 50vw;
                margin-right: 100px;
            }
        }
    }

    .title {
        color: #FFF;

        /* Display xs/Regular */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-md, 36px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
        letter-spacing: -0.72px;

        ${({ theme }) => theme.mediaQueries.md} {
            /* Display xl/Semibold */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-xl, 60px);
            font-style: normal;
            font-weight: 600;
            line-height: var(--font-line-height-Display-xl, 72px); /* 120% */
            letter-spacing: -1.2px;
        }
    }

    .subtitle {
        color: #FFF;

        ${({ theme }) => theme.mediaQueries.md} {
            /* Display xs/Regular */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-xs, 24px);
            font-style: normal;
            font-weight: 400;
            line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */
        }
    }
`

const TonNetwork = () => {
  return (
    <div className="container">
      <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
        <TonVideoWrapper>
          <video src="/images/landing-2/TON.webm" muted autoPlay loop></video>
        </TonVideoWrapper>
        <TonTitle>
          TON Network
        </TonTitle>
      </Flex>
      <Features>
        <Feature alignItems={'center'}>
          <img src="/images/landing-2/copy-trade.png" alt="copy-trade" />
          <Flex flexDirection={'column'} gap={'1rem'}>
            <div className="title">
              Copy Trade
            </div>
            <div className="subtitle">
              Effortlessly copy trades from top traders. Invest smarter, easier.
            </div>
          </Flex>
        </Feature>
        <Feature alignItems={'center'}>
          <img src="/images/landing-2/sniper-monitor.png" alt="copy-trade" />
          <Flex flexDirection={'column'} gap={'1rem'}>
            <div className="title">
              Sniper Monitor
            </div>
            <div className="subtitle">
              Sniper Monitor coming soon. Execute trades instantly. Never miss a profit.
            </div>
          </Flex>
        </Feature>
        <Feature alignItems={'center'}>
          <img src="/images/landing-2/book-trending.png" alt="book-trending" />
          <Flex flexDirection={'column'} gap={'1rem'}>
            <div className="title">
              Book trending
            </div>
            <div className="subtitle">
              Get your token to the top of trending lists, increasing visibility to users.
            </div>
          </Flex>
        </Feature>
      </Features>
    </div>
  )
}

export default TonNetwork
