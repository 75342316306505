import React from 'react'
import { Flex } from '../../../../widgets'
import styled from 'styled-components'
import Button from '../../../../widgets/Zeus/Button'

export const BSCVideoWrapper = styled.div`
    width: 200px;
    height: 200px;
    position: relative;

    ${({ theme }) => theme.mediaQueries.md} {
        width: 300px;
        height: 300px;
    }

    video {
        width: 300px;
        position: absolute;
        top: -2%;
        left: 50%;
        transform: translateX(-50%);

        ${({ theme }) => theme.mediaQueries.md} {
            top: 0;
            width: 400px;
        }
    }
`

const BSCTitle = styled.h2`
    color: var(--color-text-text-primary, #F1F1F1);
    text-align: center;

    /* Display md/Semibold */
    font-family: var(--font-family-Typeface, Unbounded);
    font-size: var(--font-size-Display-md, 36px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
    letter-spacing: -0.72px;

    ${({ theme }) => theme.mediaQueries.md} {
        color: var(--color-text-text-primary, #F1F1F1);
        text-align: center;

        /* Display 2xl/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-2xl, 72px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-2xl, 90px); /* 125% */
        letter-spacing: -1.44px;
    }
`

const Features = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 32px;

    ${({ theme }) => theme.mediaQueries.md} {
        gap: 64px;
    }
`

const Feature = styled(Flex)`
    gap: 16px;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.md} {
        gap: 64px;
        flex-direction: row;
    }

    img {
        width: 200px;

        ${({ theme }) => theme.mediaQueries.md} {
            width: 450px;
            margin-left: -10%;
        }
    }

    &:nth-child(2) {
        ${({ theme }) => theme.mediaQueries.md} {
            flex-direction: row-reverse;

            img {
                margin-right: -10%;
            }

            .subtitle {
                max-width: 50vw;
                margin-right: 100px;
            }
        }
    }

    .title {
        color: #FFF;

        /* Display xs/Regular */
        /* Display xs/Regular */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-xs, 24px);
        font-style: normal;
        font-weight: 400;
        line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */

        ${({ theme }) => theme.mediaQueries.md} {
            color: #FFF;

            /* Display md/Semibold */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-md, 36px);
            font-style: normal;
            font-weight: 600;
            line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
            letter-spacing: -0.72px;
        }
    }

    .subtitle {
        color: #FFF;

        ${({ theme }) => theme.mediaQueries.md} {
            /* Display xs/Regular */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-xs, 24px);
            font-style: normal;
            font-weight: 400;
            line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */
        }
    }
`

const BSCNetwork = () => {
  return (
    <div className="container" id={'bsc-section'}>
      <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
        <BSCVideoWrapper>
          <video src="/images/landing-2/BSC.webm" muted autoPlay loop></video>
        </BSCVideoWrapper>
        <BSCTitle>
          BSC Network
        </BSCTitle>
      </Flex>
      <Features>
        <Feature alignItems={'center'}>
          <img src="/images/landing-2/volume-booster.png" alt="volume-booster" />
          <Flex flexDirection={'column'} gap={'1rem'}>
            <div className="title">
              Zeus88 BSC Volume Booster
            </div>
            <div className="subtitle">
              Just three clicks stand between you and a $10 million boost in your projects volume. Dont wait, act now
              to create a FOMO effect.
            </div>
            <Flex marginTop={'1.5rem'}>
              <Button variant={'outlined'}>
                Access BOT
              </Button>
            </Flex>
          </Flex>
        </Feature>
        <Feature alignItems={'center'}>
          <img src="/images/landing-2/increase-volume.png" alt="increase-volume" />
          <Flex flexDirection={'column'} gap={'1rem'}>
            <div className="title">
              Increase Holder Volume - Expand Your Market Reach
            </div>
            <div className="subtitle">
              Zeus88 Bot helps you seamlessly increase both trading volume and the number of holders. With just a few
              steps, amplify your projects influence and market presence.
            </div>
            <Flex marginTop={'1.5rem'}>
              <Button variant={'outlined'}>
                Access BOT
              </Button>
            </Flex>
          </Flex>
        </Feature>
        <Feature alignItems={'center'}>
          <img src="/images/landing-2/increase-holder.png" alt="increase-holder" />
          <Flex flexDirection={'column'} gap={'1rem'}>
            <div className="title">
              Effortless Token Distribution - Maximize Your Holder Base
            </div>
            <div className="subtitle">
              Zeus88s Multi Send Token Bot allows you to efficiently distribute tokens, boosting the number of holders
              and ensuring broader token adoption with ease.
            </div>
            <Flex marginTop={'1.5rem'}>
              <Button variant={'outlined'}>
                Access BOT
              </Button>
            </Flex>
          </Flex>
        </Feature>
      </Features>
    </div>
  )
}

export default BSCNetwork
