import React from 'react'
import { Flex } from '../../../../widgets'
import styled from 'styled-components'

const Title = styled.div`
    color: var(--color-text-text-primary, #F1F1F1);

    /* Display sm/Semibold */
    font-family: var(--font-family-Typeface, Unbounded);
    font-size: var(--font-size-Display-sm, 30px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--font-line-height-Display-sm, 38px); /* 126.667% */

    ${({ theme }) => theme.mediaQueries.md} {
        color: var(--color-text-text-primary, #F1F1F1);

        /* Display md/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-md, 36px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
        letter-spacing: -0.72px;
    }
`

const Subtitle = styled.div`
    color: var(--color-text-text-primary, #F1F1F1);

    /* Display xs/Semibold */
    font-family: var(--font-family-Typeface, Unbounded);
    font-size: var(--font-size-Display-xs, 24px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */

    ${({ theme }) => theme.mediaQueries.md} {
        /* Display sm/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-sm, 30px);
        font-style: normal;
        font-weight: 400;
        line-height: var(--font-line-height-Display-sm, 38px); /* 126.667% */
    }
`

const Wrapper = styled(Flex)`
    margin-top: 1rem;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.md} {
        flex-direction: row;
    }

    video.thunder {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .user-block {
        position: relative;
        overflow: hidden;
        width: 200px;
        height: 40vh;
        flex-shrink: 0;

        ${({ theme }) => theme.mediaQueries.md} {
            width: 400px;
        }

        video.user {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            object-fit: cover;
        }
    }
`

const Users = styled(Flex)`
    .row {
        flex-direction: column;
        gap: 1rem;

        ${({ theme }) => theme.mediaQueries.md} {
            flex-direction: row;
            gap: 2rem;
        }

        & + .row {
            margin-top: 1rem;

            ${({ theme }) => theme.mediaQueries.md} {
                margin-top: 2rem;
            }
        }

        &:nth-child(2) {
            ${({ theme }) => theme.mediaQueries.md} {
                transform: translateX(100px);
            }
        }
    }

    .user {
        border-radius: 12px;
        border: 2px solid var(--color-gray-gray-800, #242424);
        background: var(--color-background-bg-primary-active, #0C0C0C);

        .avatar {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            overflow: hidden;
        }

        .name {
            color: #FFF;

            /* Display xs/Semibold */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-xs, 24px);
            font-style: normal;
            font-weight: 600;
            line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */
        }
    }
`

const USERS = [
  {
    avatar: '/images/landing-2/Avatar.png',
    name: 'Ann Siphron',
  },
  {
    avatar: '/images/landing-2/avatar-1.png',
    name: 'Mira Dokidis',
  },
  {
    avatar: '/images/landing-2/avatar-2.png',
    name: 'Ryan Dorwart',
  },
  {
    avatar: '/images/landing-2/avatar-3.png',
    name: 'Ann Botosh',
  },
  {
    avatar: '/images/landing-2/avatar-3.png',
    name: 'Ann Botosh',
  },
  {
    avatar: '/images/landing-2/avatar-4.png',
    name: 'Gustavo Lubin',
  },
  {
    avatar: '/images/landing-2/avatar-5.png',
    name: 'Ahmad Rosser',
  },
  {
    avatar: '/images/landing-2/avatar-6.png',
    name: 'Giana Vaccaro',
  },
  {
    avatar: '/images/landing-2/avatar-7.png',
    name: 'Justin Calzonih',
  },
  {
    avatar: '/images/landing-2/avatar-7.png',
    name: 'Cheyenne Siphron',
  },
]

const Referral = () => {
  return (
    <Flex padding={'2rem 0'}>
      <div className="container">
        <Flex flexDirection={'column'} gap={'1rem'}>
          <Title>Referral System</Title>
          <Subtitle>
            Share your success with Zeus88 Bot! Each successful referral brings valuable rewards for both you and the
            new user.
          </Subtitle>
        </Flex>
        <Wrapper gap={'1rem'} position={'relative'} alignItems={'center'}>
          <video src="/images/landing-2/BG%20Thunder.mp4" loop muted autoPlay className={'thunder'}></video>
          <div className="user-block">
            <video src="/images/landing-2/User.webm" loop muted autoPlay className={'user'}></video>
          </div>
          <Users flex={1} position={'relative'} flexDirection={'column'}>
            <Flex className="row">
              {
                USERS.slice(0, 3).map((user, index) => (
                  <Flex className="user" padding={'1rem 2rem'} alignItems={'center'} gap={'1rem'} key={index}>
                    <Flex className="avatar" alignItems={'center'} justifyContent={'center'}>
                      <img src={user.avatar} alt="avatar" />
                    </Flex>
                    <div className="name">{user.name}</div>
                  </Flex>
                ))
              }
            </Flex>
            <Flex className="row">
              {
                USERS.slice(3, 6).map((user, index) => (
                  <Flex className="user" padding={'1rem 2rem'} alignItems={'center'} gap={'1rem'} key={index}>
                    <Flex className="avatar" alignItems={'center'} justifyContent={'center'}>
                      <img src={user.avatar} alt="avatar" />
                    </Flex>
                    <div className="name">{user.name}</div>
                  </Flex>
                ))
              }
            </Flex>
            <Flex className="row">
              {
                USERS.slice(6, 9).map((user, index) => (
                  <Flex className="user" padding={'1rem 2rem'} alignItems={'center'} gap={'1rem'} key={index}>
                    <Flex className="avatar" alignItems={'center'} justifyContent={'center'}>
                      <img src={user.avatar} alt="avatar" />
                    </Flex>
                    <div className="name">{user.name}</div>
                  </Flex>
                ))
              }
            </Flex>
          </Users>
        </Wrapper>
      </div>
    </Flex>
  )
}

export default Referral
