import styled from 'styled-components'
import Header from './components/Header'
import HeroBanner from './components/HeroBanner'
import Solution from './components/Solution'
import CircularSection from './components/CircularSection'
import TonNetwork from './components/TonNetwork'
import BscNetwork from './components/BSCNetwork'
import Vision from './components/Vision'
import Referral from './components/Referral'
import WhyZeusBot from './components/WhyZeusBot'
import Footer from './components/Footer'

const LandingPage = () => {
  return (
    <Wrapper className=''>
      <Header />
      <HeroBanner />
      <Solution />
      <CircularSection />
      <TonNetwork />
      <BscNetwork />
      <Vision />
      <Referral />
      <WhyZeusBot />
      <Footer />
    </Wrapper>
  )
}

export default LandingPage

const Wrapper = styled.div`
  color: #fff;
  background-color: #000000;
`
