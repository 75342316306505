import styled from 'styled-components'
import { Flex } from '../../../../widgets'
import Button from '../../../../widgets/Zeus/Button'

const HeroBanner = () => {
  return (
    <Wrapper className="">
      <video src="/images/landing-2/bg-video.mov" autoPlay={true} controls={false} muted loop={true}></video>
      <div className="container">
        <div className={'zeus'}>
          <img src="/images/landing-2/zeus.png" alt="zeus" />
        </div>
        <Flex className="content" flexDirection={'column'} gap={'24px'}>
          <h1 className="title">
            The future of finance, at your fingertips.
          </h1>
          <p>
            Zeus88 Bot is a wallet and trading tool that helps you manage your crypto assets efficiently and securely.
            It has features like Book Trending to increase visibility and Volume Buffing to attract more buyers.
          </p>
          <Flex className="content" gap={'16px'}>
            <Button variant={'filled'} onClick={() => window.open('https://t.me/Zeus88Bot_bot', '_blank')}>
              Open BOT
            </Button>
            <Button variant={'outlined'} onClick={() => window.open('https://t.me/wedxdofficial', '_blank')}>
              Join Community
            </Button>
          </Flex>
          <small>Introducing Features and User Management. <a href="#">Read more</a></small>
        </Flex>
      </div>
    </Wrapper>
  )
}

export default HeroBanner

const Wrapper = styled.div`
    position: relative;
    height: 100vh;
    max-height: 1080px;
    padding: 96px 0 0;

    ${({ theme }) => theme.mediaQueries.lg} {
        padding: 96px 32px 0;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }

    .zeus {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .container {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;

        ${({ theme }) => theme.mediaQueries.lg} {
            background-color: transparent;
            display: flex;
            align-items: center;
        }

        .content {
            position: relative;

            ${({ theme }) => theme.mediaQueries.lg} {
                max-width: 60%;
            }

            .title {
                color: #FFF;
                margin-top: 2rem;

                /* Display 2xl/Semibold */
                font-family: var(--font-family-Typeface, Unbounded);
                font-size: var(--font-size-Display-md, 36px);
                font-style: normal;
                font-weight: 600;
                line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
                letter-spacing: -1.44px;

                ${({ theme }) => theme.mediaQueries.lg} {
                    margin-top: 4rem;
                    font-size: var(--font-size-Display-2xl, 72px);
                    line-height: var(--font-line-height-Display-2xl, 90px); /* 125% */
                }
            }

            p {
                ${({ theme }) => theme.mediaQueries.lg} {
                    font-size: var(--font-size-Display-xs, 24px);
                    line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */
                }
            }

            small {
                color: var(--color-text-text-primary, #F1F1F1);

                /* Text sm/Regular */
                font-family: var(--font-family-Typeface, Unbounded);
                font-size: var(--font-size-Text-sm, 14px);
                font-style: normal;
                font-weight: 400;
                line-height: var(--font-line-height-Text-sm, 20px); /* 142.857% */

                a {
                    color: var(--color-text-text-info-primary, #33E6FF);
                    font-family: var(--font-family-Typeface, Unbounded);
                    font-size: var(--font-size-Text-sm, 14px);
                    font-style: normal;
                    font-weight: 400;
                    line-height: var(--font-line-height-Text-sm, 20px); /* 142.857% */
                    text-decoration-line: underline;
                }
            }
        }
    }
`
