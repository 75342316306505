import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../../../widgets'
import Button from '../../../../widgets/Zeus/Button'

const Wrapper = styled.div`
    background: url('/images/landing-2/lightning-bg.png') no-repeat center center;
    position: relative;
    padding: 1rem;

    img[alt='gradient'] {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        ${({ theme }) => theme.mediaQueries.md} {
            top: 50%;
        }
    }

    img[alt='zeus'] {
        width: 150px;
        
        ${({ theme }) => theme.mediaQueries.md} {
            width: 300px;
        }
    }

    .title {
        color: var(--color-text-text-primary, #F1F1F1);
        text-align: center;

        /* Display md/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-md, 36px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
        letter-spacing: -0.72px;
    }

    .subtitle {
        color: var(--color-text-text-primary, #F1F1F1);
        text-align: center;

        /* Display sm/Regular */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-sm, 30px);
        font-style: normal;
        font-weight: 400;
        line-height: var(--font-line-height-Display-sm, 38px); /* 126.667% */
    }
`

const Cards = styled.div`
    display: flex;
    gap: 24px;
    flex-direction: column;
    margin-top: 1rem;

    ${({ theme }) => theme.mediaQueries.md} {
        flex-direction: row;
        margin-top: -64px;
        gap: 48px;
    }

    .card {
        flex: 1;
        background-color: #181818;
        background-image: url('/images/landing-2/card-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;

        border-radius: 16px;
        border: 1px solid var(--color-gray-gray-50, #F1F1F1);

        /* Background blur/xl */
        backdrop-filter: blur(20px);

        padding: var(--font-size-Display-md, 36px) var(--font-line-height-Text-lg, 28px);

        .icon {
            height: 200px;
            img {
                height: 100%;
            }
        }

        .title {
            color: #FFF;
            text-align: center;

            /* Display xs/Semibold */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-xs, 24px);
            font-style: normal;
            font-weight: 600;
            line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */
        }

        .subtitle {
            color: #FFF;
            text-align: center;
            font-variant-numeric: lining-nums proportional-nums;

            /* Text md/Regular */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Text-md, 16px);
            font-style: normal;
            font-weight: 400;
            line-height: var(--font-line-height-Text-md, 24px); /* 150% */
        }
        
        ${({ theme }) => theme.mediaQueries.md} {
            margin-bottom: 120px;
            
            &:nth-child(2) {
                margin-top: 120px;
                margin-bottom: 0;
            }
        }
    }
`

const Solution = () => {
  return (
    <Wrapper>
      <img src="/images/landing-2/solution-bg.png" alt="gradient" />
      <div className="container">
        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'1.5rem'}>
          <img src="/images/landing-2/zeus-2.png" alt="zeus" />
          <h2 className="title">THE ALL-IN-ONE SOLUTION</h2>
          <Button variant={'filled'}>
            Start Trading
          </Button>
        </Flex>
        <Cards>
          <Flex className="card" flexDirection={'column'} gap={'24px'} justifyContent={'center'} alignItems={'center'}>
            <div className="icon">
              <img src="/images/landing-2/speed-coin.png" alt="icon" />
            </div>
            <div className="title">Lightning Speed Swap</div>
            <div className="subtitle">Absolute compatibility with DEXs. Ensures maximum multi-threaded transaction speed</div>
          </Flex>
          <Flex className="card" flexDirection={'column'} gap={'24px'} justifyContent={'center'} alignItems={'center'}>
            <div className="icon">
              <img src="/images/landing-2/user-like.png" alt="icon" />
            </div>
            <div className="title">Flat UI Easy To Use</div>
            <div className="subtitle">Intuitive and simple interface even for new or advanced users</div>
          </Flex>
          <Flex className="card" flexDirection={'column'} gap={'24px'} justifyContent={'center'} alignItems={'center'}>
            <div className="icon">
              <img src="/images/landing-2/2fa.png" alt="icon" />
            </div>
            <div className="title">Private Transactions</div>
            <div className="subtitle">User transactions are executed privately, minimizing losses due to slippage</div>
          </Flex>
        </Cards>
      </div>
    </Wrapper>
  )
}

export default Solution
