import React from 'react'
import { Flex } from '../../../../widgets'
import styled from 'styled-components'

const Wrapper = styled(Flex)`
    background: url('/images/landing-2/circular-divider.png') no-repeat top center;
    background-size: cover;
    height: 20vh;
    margin: 3rem auto;
    position: relative;
    
    &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
`

const CircularSection = () => {
  return (
    <Wrapper className={'container'}>
    </Wrapper>
  )
}

export default CircularSection
