import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../../../widgets'

const Wrapper = styled(Flex)`
    min-height: 100vh;

    .title {
        color: var(--color-text-text-primary, #F1F1F1);
        text-align: center;

        /* Display md/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-md, 36px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
        letter-spacing: -0.72px;
    }

    .subtitle {
        color: var(--color-text-text-primary, #F1F1F1);
        text-align: center;

        ${({ theme }) => theme.mediaQueries.md} {
            /* Display sm/Regular */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-sm, 30px);
            font-style: normal;
            font-weight: 400;
            line-height: var(--font-line-height-Display-sm, 38px); /* 126.667% */
        }
    }

    .container {
        max-width: 1000px;
        padding: 0 1rem;
    }
    
    .video-bg-1 {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%);
        width: 100vw;
    }
    
    .video-bg-2 {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateX(50%);
        width: 100vw;
    }
`

const VideoWrapper = styled.div`
    width: 100%;
    height: 40vh;
    position: relative;

    video {
        width: 100%;
        position: absolute;
        top: -5%;
        left: 50%;
        transform: translateX(-50%);

        ${({ theme }) => theme.mediaQueries.md} {
            top: -15%;
        }

        ${({ theme }) => theme.mediaQueries.xl} {
            top: -5%;
        }
    }
`

const Vision = () => {
  return (
    <Wrapper flexDirection={'column'} alignItems={'center'} justifyContent={'center'} position={'relative'}>
      <video src="/images/landing-2/BG%20Landing%20page.mp4" className={'video-bg-1'} muted loop autoPlay></video>
      <video src="/images/landing-2/BG%20Landing%20page.mp4" className={'video-bg-2'} muted loop autoPlay></video>
      <Flex flexDirection={'column'} gap={'1rem'} className="container" position={'relative'}>
        <div className="title">
          Vision
        </div>
        <div className={'subtitle'}>
          Zeus88 Bot aims to become the ultimate trading tool for all traders, from beginners to experts. We believe
          that
          automated trading technology is the future of the crypto market, and Zeus88 Bot will help you seize that
          opportunity
        </div>
        <VideoWrapper>
          <video src="/images/landing-2/Vission.webm" muted loop autoPlay></video>
        </VideoWrapper>
      </Flex>
    </Wrapper>
  )
}

export default Vision
