import React from 'react'
import { Flex } from '../../../widgets'
import styled from 'styled-components'

const Wrapper = styled(Flex)`
    padding: 2rem 0;
    
    ${({ theme }) => theme.mediaQueries.xl} {
        padding: 10rem 0;
    }
`
const BGWrapper = styled(Flex)`
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;

    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 125vw;
    }

    video {
        width: 100%;
    }
`

const VideoWrapper = styled.div`
    width: 100px;
    height: 100px;
    position: relative;

    ${({ theme }) => theme.mediaQueries.xl} {
        width: 200px;
        height: 200px;
    }

    video {
        width: 200px;
        position: absolute;
        top: -20%;
        left: 50%;
        transform: translateX(-50%);

        ${({ theme }) => theme.mediaQueries.xl} {
            top: -20%;
            width: 400px;
        }
    }
`

const BSCBox = styled(Flex)`
    margin: 1rem;
    padding: 1rem;
    border-radius: 12px;
    border: 3px solid #0C0C0C;

    .inner-1 {
        background: #0C0C0C;
        border-radius: 12px;

        .inner-2 {
            border: 3px solid transparent;
            border-radius: 12px;
            background: linear-gradient(#040709, #040709) padding-box, linear-gradient(145deg, #FDBE19 0%, #FDBE19 50%, #1372FF 100%) border-box;
            padding: 1rem;

            ${({ theme }) => theme.mediaQueries.xl} {
                padding: 2rem;
            }
        }
    }
`

const Title = styled.div`
    color: var(--color-text-text-primary, #F1F1F1);

    /* Display xs/Semibold */
    font-family: var(--font-family-Typeface, Unbounded);
    font-size: var(--font-size-Display-xs, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */

    ${({ theme }) => theme.mediaQueries.xl} {
        /* Display sm/Semibold */
        font-family: var(--font-family-Typeface, Unbounded);
        font-size: var(--font-size-Display-sm, 30px);
        font-style: normal;
        font-weight: 600;
        line-height: var(--font-line-height-Display-sm, 38px); /* 126.667% */
    }

    & + .subtitle {
        max-width: 300px;
    }
`

const Cards = styled(Flex)`
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    
    ${({ theme }) => theme.mediaQueries.xl} {
        flex-direction: row;
        gap: 0;
    }
`

const Card = styled(Flex)`
    border-radius: 12px;
    border: 2px solid var(--color-gray-gray-800, #242424);
    padding: 20px 24px;
    background: linear-gradient(90deg, #83691E 0%, #496C50 25%, #142D3A 50%, #0C0C0C 100%);

    ${({ theme }) => theme.mediaQueries.xl} {
        &.deco-right-top {
            margin: 3rem;
            
            &:before {
                content: '';
                position: absolute;
                bottom: -3px;
                right: -1rem;
                width: 4rem;
                height: 120px;
                border-top: 3px solid #0C0C0C;
                border-right: 3px solid #0C0C0C;
                border-radius: 0 12px 0 0;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: calc(-3px - 1rem);
                right: 0;
                width: 4rem;
                height: 120px;
                border-top: 3px solid #0C0C0C;
                border-right: 3px solid #0C0C0C;
                border-radius: 0 12px 0 0;
            }
        }

        &.deco-bottom-right {
            margin: 0 3rem 6rem;
            
            &:before {
                content: '';
                position: absolute;
                bottom: -103px;
                left: 175px;
                width: 160px;
                height: 200px;
                border-right: 3px solid #0C0C0C;
                border-bottom: 3px solid #0C0C0C;
                border-radius: 0 0 12px 0;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: calc(-103px - 1rem);
                left: calc(175px + 1rem);
                width: 160px;
                height: calc(200px + 1rem);
                border-right: 3px solid #0C0C0C;
                border-bottom: 3px solid #0C0C0C;
                border-radius: 0 0 12px 0;
            }
        }

        &.deco-top-left {
            margin: 3rem 4rem;
            
            &:before {
                content: '';
                position: absolute;
                top: -103px;
                right: 175px;
                width: 160px;
                height: 152px;
                border-top: 3px solid #0C0C0C;
                border-left: 3px solid #0C0C0C;
                border-radius: 12px 0 0 0;
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(-103px - 1rem);
                right: calc(175px + 1rem);
                width: 160px;
                height: calc(152px + 1rem);
                border-top: 3px solid #0C0C0C;
                border-left: 3px solid #0C0C0C;
                border-radius: 12px 0 0 0;
            }
        }

        &.deco-top-right {
            margin: 6rem 2rem 2rem;
            
            &:before {
                content: '';
                position: absolute;
                top: -56px;
                left: 175px;
                width: 160px;
                height: 152px;
                border-top: 3px solid #0C0C0C;
                border-right: 3px solid #0C0C0C;
                border-radius: 0 12px 0 0;
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(-56px - 1rem);
                left: calc(175px + 1rem);
                width: 160px;
                height: calc(152px + 1rem);
                border-top: 3px solid #0C0C0C;
                border-right: 3px solid #0C0C0C;
                border-radius: 0 12px 0 0;
            }
        }

        &.deco-top-bottom {
            margin: 2rem 2rem 0;
            
            &:before {
                content: '';
                position: absolute;
                bottom: 178px;
                left: calc(50% + .5rem);
                transform: translateX(-50%);
                width: 1rem;
                height: 395px;
                border-right: 3px solid #0C0C0C;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 178px;
                left: calc(50% - .5rem);
                transform: translateX(-50%);
                width: 1rem;
                height: calc(395px - 1rem);
                border-right: 3px solid #0C0C0C;
            }
        }
    }
`

const BscNetwork = () => {
  return (
    <Wrapper flexDirection={'column'} position={'relative'}>
      <BGWrapper>
        <div>
          <video src="/images/landing-2/BG%20Landing%20page.mp4" muted autoPlay loop></video>
        </div>
      </BGWrapper>
      <div className="container">
        <Cards position={'relative'}>
          <Flex flex={1} justifyContent={'end'} position={'relative'}>
            <Card alignItems={'center'} gap={'1rem'} className={'deco-right-top'}>
              <img src="/images/landing-2/volume-bot.png" alt="volume" />
              <Flex gap={'.5rem'} flexDirection={'column'}>
                <Title>Volume bot</Title>
                <div className="subtitle">Generate trading volume with detailed configurations.</div>
              </Flex>
            </Card>
          </Flex>
          <Flex flex={1} justifyContent={'end'} position={'relative'}>
            <Card alignItems={'center'} gap={'1rem'} className={'deco-bottom-right'}>
              <img src="/images/landing-2/token-bot.png" alt="token" />
              <Flex gap={'.5rem'} flexDirection={'column'}>
                <Title>Multi send token bot</Title>
                <div className="subtitle">Increase the number of holders without limits</div>
              </Flex>
            </Card>
          </Flex>
        </Cards>
        <Flex justifyContent={'center'} position={'relative'}>
          <BSCBox justifyContent={'center'}>
            <Flex className={'inner-1'} padding={'1rem'}>
              <Flex className={'inner-2'} flexDirection={'column'} alignItems={'center'} gap={'1rem'}>
                <VideoWrapper>
                  <video src="/images/landing-2/BSC.webm" muted autoPlay loop></video>
                </VideoWrapper>
                <Title>
                  BSC Network
                </Title>
              </Flex>
            </Flex>
          </BSCBox>
        </Flex>
        <Cards position={'relative'}>
          <Flex flex={1} justifyContent={'end'} position={'relative'}>
            <Card alignItems={'center'} gap={'1rem'} className={'deco-top-left'}>
              <img src="/images/landing-2/bsc-up-holder.png" alt="volume" />
              <Flex gap={'.5rem'} flexDirection={'column'}>
                <Title>Up Volume to holder</Title>
                <div className="subtitle">Boost trading volume and holder numbers by generating wallets and executing buy/sell orders.</div>
              </Flex>
            </Card>
          </Flex>
          <Flex flex={1} justifyContent={'end'} position={'relative'}>
            <Card alignItems={'center'} gap={'1rem'} className={'deco-top-right'}>
              <img src="/images/landing-2/bsc-sniper-monitor.png" alt="token" />
              <Flex gap={'.5rem'} flexDirection={'column'}>
                <Title>Sniper Monitor</Title>
                <div className="subtitle">Monitor token pools and execute trades instantly when the pool opens, ensuring you never miss a profit.</div>
              </Flex>
            </Card>
          </Flex>
        </Cards>
        <Flex flex={1} position={'relative'} justifyContent={'center'} marginTop={'1rem'}>
          <Card alignItems={'center'} gap={'1rem'} className={'deco-top-bottom'}>
            <img src="/images/landing-2/bsc-copy-trade.png" alt="token" />
            <Flex gap={'.5rem'} flexDirection={'column'}>
              <Title>Copy Trade</Title>
              <div className="subtitle">Track and replicate trades of top traders, with the ability to set predefined trading percentages.</div>
            </Flex>
          </Card>
        </Flex>
      </div>
    </Wrapper>
  )
}

export default BscNetwork
