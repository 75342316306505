import { Button as AntdButton, Drawer as AntdDrawer, DrawerProps } from 'antd'
import styled from 'styled-components'
import Button from '../../../../widgets/Zeus/Button'

interface DrawerMenuProps extends DrawerProps {
  links: { title: string; link: string }[]
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({ links, ...props }) => {
  return (
    <Drawer title="Menu" placement="right" {...props}>
      <div className="flex list-link">
        {links?.map((link) => (
          <a href={link?.link} key={link?.link}>
            {link?.title}
          </a>
        ))}
        <Button variant={'outlined'} onClick={() => window.open('https://t.me/Zeus88Bot_bot', '_blank')}>
          Open BOT
        </Button>
      </div>
    </Drawer>
  )
}

export default DrawerMenu

const Drawer = styled(AntdDrawer)`
    .ant-drawer-content {
        background: linear-gradient(180deg, #000000 0%, #0c0c00 100%);
        color: #fff;
    }

    .ant-drawer-header {
        .ant-drawer-header-title {
            .ant-drawer-title {
                color: #fff;
            }

            button > span {
                color: #fff;
            }
        }

        background: linear-gradient(180deg, #000000 0%, #0c0c00 100%);
        border-bottom: 2px solid;
        border-image-source: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #edbd4b 24.5%, rgba(161, 71, 5, 0) 100%);
    }

    .list-link {
        flex-direction: column;
        gap: 16px;
    }
`
