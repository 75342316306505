import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../../../widgets'

const Wrapper = styled(Flex)`
    background: url('/images/landing-2/why-zeus-bg.png') no-repeat center center;
    background-size: cover;
    border-radius: 24px;
    overflow: hidden;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.xl} {
        flex-direction: row;
    }
`

const Title = styled.div`
    color: var(--color-text-text-primary, #F1F1F1);

    /* Display sm/Regular */
    font-family: var(--font-family-Typeface, Unbounded);
    font-size: var(--font-size-Display-sm, 30px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-line-height-Display-sm, 38px); /* 126.667% */
`

const GradientTitle = styled.div`

    /* Display md/Semibold */
    font-family: var(--font-family-Typeface, Unbounded);
    font-size: var(--font-size-Display-md, 36px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--font-line-height-Display-md, 44px); /* 122.222% */
    letter-spacing: -0.72px;

    background: linear-gradient(90deg, #FF9C13 0%, #FDBE19 25%, #13FFAA 75%, #1372FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const Notch = styled(Flex)`
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    padding: 1rem;
    background-color: black;
    border-radius: 16px;
    margin: 1rem 1rem 0;

    ${({ theme }) => theme.mediaQueries.xl} {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0;
        border-radius: 0 0 16px 16px;
    }
`

const Content = styled(Flex)`
    flex-direction: column;
    gap: 1rem;
    
    img[alt='subtract'] {
        position: absolute;
        top: 0;
        left: 1rem;
        width: 80%;
        border-radius: 16px;
        
        ${({ theme }) => theme.mediaQueries.xl} {
            width: 35%;
        }
    }
    
    img[alt='trending'] {
        width: 50%;
        padding-left: 2rem;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
        flex-direction: row;
    }
`

const WhyZeusBot = () => {
  return (
    <Flex className="container" flexDirection={'column'} margin={'2rem auto'}>
      <Wrapper>
        <Notch>
          <Title>Why Zeus88Bot?</Title>
          <GradientTitle>Expand your trading</GradientTitle>
        </Notch>
        <Content>
          <img src="/images/landing-2/Subtract.png" alt="subtract" />
          <Flex flex={1} flexDirection={'column'} padding={'2rem'} justifyContent={'end'} gap={'2rem'} position={'relative'}>
            <img src="/images/landing-2/trending.png" alt="trending" />
            <Title>
              Zeus88 Bot is not just a trading bot - it&apos;s a comprehensive solution to enhance your trading
              experience,
              optimize profits with automated, multi-chain, and easy-to-use features
            </Title>
          </Flex>
          <Flex flex={1}>
            <img src="/images/landing-2/zeus-3.png" alt="" />
          </Flex>
        </Content>
      </Wrapper>
    </Flex>
  )
}

export default WhyZeusBot
