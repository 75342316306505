import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../../../widgets'

const Wrapper = styled.div`
    position: relative;

    video {
        width: 100%;
        height: 100%;
        min-height: 600px;
        object-fit: cover;
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .title {
            color: var(--color-text-text-primary, #F1F1F1);
            text-align: center;

            /* Display xl/Semibold */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-xl, 60px);
            font-style: normal;
            font-weight: 600;
            line-height: var(--font-line-height-Display-xl, 72px); /* 120% */
            letter-spacing: -1.2px;
        }

        .subtitle {
            color: #FFF;
            text-align: center;

            /* Display xs/Regular */
            font-family: var(--font-family-Typeface, Unbounded);
            font-size: var(--font-size-Display-xs, 24px);
            font-style: normal;
            font-weight: 400;
            line-height: var(--font-line-height-Display-xs, 32px); /* 133.333% */
        }
    }
`

const SolanaXTron = () => {
  return (
    <div className={'container'} id={'sol-section'}>
      <Wrapper>
        <video src="/images/landing-2/BG%20Landing%20page.mp4" muted autoPlay loop></video>
        <Flex className="content" justifyContent={'center'} alignItems={'center'} gap={'2rem'} flexDirection={'column'}>
          <img src="/images/landing-2/solanatron.png" alt="" />
          <div className="title">
            SOLANA & TRON Network
          </div>
          <div className="subtitle">
            Coming soon
          </div>
        </Flex>
      </Wrapper>
    </div>
  )
}

export default SolanaXTron
