import React, { FC } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

interface ButtonProps extends SpaceProps {
  children: React.ReactNode
  onClick?: () => void
  variant: 'filled' | 'outlined' | 'transparent'
}

const ButtonWrapper = styled.button<ButtonProps>`
    ${space};
    padding: .5rem 1rem;
    white-space: nowrap;
    cursor: pointer;

    color: ${variant => variant.variant === 'filled' ? '#282828' : '#FFC226'};
    text-align: center;
    text-shadow: 0px 11px 23px rgba(255, 255, 255, 0.13);

    /* Text xl/Semibold */
    font-family: var(--font-family-Typeface, Unbounded);
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    ${({ theme }) => theme.mediaQueries.md} {
        font-size: var(--font-size-Text-xl, 20px);
        line-height: var(--font-line-height-Text-xl, 30px); /* 150% */
        padding: 1rem 2rem;
    }

    border: 2px solid transparent;
    border-radius: 12px;

    background: ${variant => variant.variant === 'filled' ? 'radial-gradient(50% 50% at 50% 50%, #FEC601 0%, #FDB043 100%)' : variant.variant === 'transparent' ? 'linear-gradient(#04070980, #04070980) padding-box, linear-gradient(20deg, #FFC700 0%, #FFFFFF0A 50%, #FF6B00 100%) border-box' : 'linear-gradient(#040709, #040709) padding-box, linear-gradient(20deg, #FFC700 0%, #FFFFFF0A 50%, #FF6B00 100%) border-box'};
    ${({ variant }) => variant === 'transparent' && 'backdrop-filter: blur(5px);'}
    
    &:hover {
        opacity: .9;
    }
`

const Button: FC<ButtonProps> = ({ children, variant, onClick }) => {
  return (
    <ButtonWrapper variant={variant} onClick={onClick}>
      {children}
    </ButtonWrapper>
  )
}

export default Button
